/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import {FC} from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/index"

const Layout:FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query CompanyNameQuery {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `)

  return (
    <div className="flex flex-col h-screen justify-between">
      <Header companyName={data.site.siteMetadata?.companyName} />
      <main className="flex-grow">{children}</main>
      {/* <Footer></Footer> */}
    </div>
  )
}

export default Layout
